









import { Component, Vue } from "vue-property-decorator";
import NotificationBar from "./components/notification-bar/NotificationBar.vue";

@Component({
  components: { NotificationBar },
})
export default class App extends Vue {}
