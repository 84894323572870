import { IModuleConfigBase } from "@/models/modules/imodule-config-base";
import { Line } from "./line.model";
import { HorizontalLine } from "./horizontal-line.model";
import { Landmark } from "./landmark.model";
import { MiddleLandmark } from "./middle-landmark.model";
import { VerticalLine } from "./vertical-line.model";
import { Scene } from "@/models/scenes/scene.model";
import { Direction } from "@/models/point.enum";
import { TopLeftLandmark } from "./top-left-landmark.model";
import { BottomRightLandmark } from "./bottom-right-landmark.model";
import { TopRightLandmark } from "./top-right-landmark.model";
import { BottomLeftLandmark } from "./bottom-left-landmark.model";

export class AlignCheck {
  public lines: Line[] = [new VerticalLine(), new HorizontalLine()];

  private selectedModuleLandmarks: Landmark[] = [];
  private otherModulesLandmarks: Landmark[] = [];
  private screenLandmarks: Landmark[] = [];

  get landmarks(): Landmark[] {
    return this.otherModulesLandmarks.concat(this.screenLandmarks);
  }

  public init(sceneWidth: number, sceneHeight: number): void {
    this.screenLandmarks.push(
      ...this.getLandmarks(0, 0, sceneWidth, sceneHeight)
    );
  }

  public onSceneChanged(scene: Scene): void {
    this.otherModulesLandmarks = scene.modules.flatMap((m) =>
      this.getLandmarksFromModule(m)
    );
  }

  public onModuleAdded(module: IModuleConfigBase): void {
    this.otherModulesLandmarks.push(...this.getLandmarksFromModule(module));
  }

  public onModuleDeleted(): void {
    this.selectedModuleLandmarks = [];
  }

  public onModuleSelected(module: IModuleConfigBase): void {
    this.selectedModuleLandmarks = this.getDynamicLandmarks(module);

    this.removeModule(module);
  }

  public onModuleUnselected(module: IModuleConfigBase): void {
    this.otherModulesLandmarks.push(...this.getLandmarksFromModule(module));
    this.selectedModuleLandmarks = [];
  }

  public onResizeStarted(direction: Direction): void {
    this.selectedModuleLandmarks = this.selectedModuleLandmarks.filter((l) =>
      l.isRelevant(direction)
    );

    this.lines.forEach((l) => l.init(direction));
  }

  public onResizeEnd(module: IModuleConfigBase): void {
    this.selectedModuleLandmarks = this.getDynamicLandmarks(module);

    this.onModuleDragEnd();
  }

  public onModuleDragged(module: IModuleConfigBase): void {
    this.selectedModuleLandmarks.forEach((lm) =>
      lm.setPosition(
        module.posX.value,
        module.posY.value,
        module.width.value,
        module.height.value
      )
    );

    for (const line of this.lines) {
      if (line.display) {
        if (!line.isStillAligned()) {
          line.reset();
        }
      } else {
        this.checkLineAlignment(line);
      }
    }
  }

  public onModuleDragEnd(): void {
    this.lines.forEach((l) => l.reset());
  }

  private removeModule(module: IModuleConfigBase): void {
    this.getLandmarksFromModule(module).forEach((lm) => {
      const landmarkIndex = this.otherModulesLandmarks.findIndex(
        (mlm) => mlm.posX === lm.posX && mlm.posY === lm.posY
      );

      if (landmarkIndex !== -1) {
        this.otherModulesLandmarks.splice(landmarkIndex, 1);
      }
    });
  }

  private checkLineAlignment(line: Line): void {
    for (const landmark of this.landmarks) {
      const alignedLandmark = this.selectedModuleLandmarks.find((clm) =>
        line.canBeAligned(landmark, clm)
      );

      if (alignedLandmark) {
        line.attach(landmark, alignedLandmark);
        break;
      }
    }
  }

  private getLandmarksFromModule(module: IModuleConfigBase): Landmark[] {
    return this.getLandmarks(
      module.posX.value,
      module.posY.value,
      module.width.value,
      module.height.value
    );
  }

  private getLandmarks(
    posX: number,
    posY: number,
    width: number,
    height: number
  ): Landmark[] {
    return [
      new TopLeftLandmark(posX, posY, width, height),
      new MiddleLandmark(posX, posY, width, height),
      new BottomRightLandmark(posX, posY, width, height),
    ];
  }

  private getDynamicLandmarks(module: IModuleConfigBase): Landmark[] {
    return [
      new TopLeftLandmark(
        module.posX.value,
        module.posY.value,
        module.width.value,
        module.height.value
      ),
      new TopRightLandmark(
        module.posX.value,
        module.posY.value,
        module.width.value,
        module.height.value
      ),
      new BottomLeftLandmark(
        module.posX.value,
        module.posY.value,
        module.width.value,
        module.height.value
      ),
      new BottomRightLandmark(
        module.posX.value,
        module.posY.value,
        module.width.value,
        module.height.value
      ),
      new MiddleLandmark(
        module.posX.value,
        module.posY.value,
        module.width.value,
        module.height.value
      ),
    ];
  }
}
