import Vue from "vue";
import Vuex from "vuex";
import { profile as colorsModule } from "./colors-module";
import { profile as dataModule } from "./data-module";
import { profile as sceneModule } from "./scene-module";
import { profile as notificationsModule } from "./notifications-module";
import { profile as userModule } from "./user-module";

Vue.use(Vuex);

// eslint-disable-next-line
export interface RootState {}

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    colorsModule,
    dataModule,
    sceneModule,
    notificationsModule,
    userModule,
  },
});
