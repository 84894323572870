import { LoginUser } from "@/models/user/login-user.model";
import { RefreshedTokens, RefreshToken } from "@/models/user/refresh-token";
import axios from "axios";
import { ServiceBase } from "./service-base";
import { RequestPasswordResetDTO } from "@/dto/login/request-password-reset.dto";
import { ResetPasswordDTO } from "@/dto/login/password-reset.dto";

export default class AuthenticationService extends ServiceBase {
  constructor() {
    super("authentication");
  }

  async login(user: LoginUser): Promise<any> {
    const r = await axios.post(
      this.urlBuilder.withFragment("authenticate").toString(),
      user
    );

    return r.data;
  }

  async refresh(token: RefreshToken): Promise<RefreshedTokens> {
    const r = await axios.post(
      this.urlBuilder.withFragment("refresh").toString(),
      token
    );

    return r.data;
  }

  async requestPasswordReset(resetMail: string): Promise<void> {
    const resetRequest = new RequestPasswordResetDTO(resetMail);

    const r = await axios.post(
      this.urlBuilder.withFragment("requestreset").toString(),
      resetRequest
    );

    return r.data;
  }

  async resetPassword(password: string, token: string): Promise<void> {
    const resetPassword = new ResetPasswordDTO(password, token);

    return axios.post(
      this.urlBuilder.withFragment("reset").toString(),
      resetPassword
    );
  }
}
