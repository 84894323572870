import { Direction } from "@/models/point.enum";
import { Landmark } from "./landmark.model";

export class TopLeftLandmark extends Landmark {
  relevantDirections = [Direction.N, Direction.W, Direction.NW];

  constructor(posX: number, posY: number, width: number, height: number) {
    super(posX, posY, width, height);
  }

  setPosition(posX: number, posY: number, width: number, height: number): void {
    this.posX = posX;
    this.posY = posY;
  }
}
