import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";

Vue.use(VueRouter);

const loginLayout = () => import("../views/login/LoginLayout.vue");

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/scene",
    name: "scene",
    component: () => import("../views/SceneView.vue"),
  },
  {
    path: "/login",
    component: loginLayout,
    children: [
      {
        name: "login",
        path: "",
        component: () => import("../views/login/LoginView.vue"),
      },
    ],
  },
  {
    path: "/reset-password",
    component: loginLayout,
    children: [
      {
        path: "request",
        name: "request",
        component: () => import("../views/login/RequestPasswordResetView.vue"),
      },
      {
        path: "request-success",
        name: "request-success",
        component: () =>
          import("../views/login/RequestPasswordResetSuccessView.vue"),
      },
      {
        path: "reset",
        name: "reset",
        component: () => import("../views/login/PasswordResetView.vue"),
      },
      {
        path: "reset-success",
        name: "reset-success",
        component: () => import("../views/login/PasswordResetSuccessView.vue"),
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/SettingsView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/reset-password/request",
    "/reset-password/request-success",
    "/reset-password/reset",
    "/reset-password/reset-success",
  ];
  const authRequired = !publicPages.includes(to.path);
  const isLoggedIn = store.getters["userModule/isLoggedIn"];

  if (authRequired && !isLoggedIn) {
    return next("/login");
  } else if (!authRequired && isLoggedIn) {
    return next("/");
  }

  next();
});

export default router;
