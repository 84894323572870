export enum SolarDataType {
  CurrentPower,
  LastDayEnergy,
  LastMonthEnergy,
  LastYearEnergy,
  LifetimeEnergy,
  EnergyConsumption,
  EnergySelfConsumption,
  EnergyProduction,
}
