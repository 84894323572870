import { IMediaElement } from "@/models/background/imedia-element";
import { Media } from "@/models/files/media.model";

export function loadMediaToElement(
  targetElement: IMediaElement,
  medias: Media[]
): void {
  const media = medias.find((m) => m.id === targetElement.value);

  if (media) {
    targetElement.mediaUrl = media.link;
  }
}
