import { Landmark } from "./landmark.model";

export class MiddleLandmark extends Landmark {
  relevantDirections = [];

  constructor(posX: number, posY: number, width: number, height: number) {
    super(posX, posY, width, height);
  }

  setPosition(posX: number, posY: number, width: number, height: number): void {
    this.posX = posX + width / 2;
    this.posY = posY + height / 2;
  }
}
