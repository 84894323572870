import { SolarDataType } from "@/models/solar-data-type.enum";
import axios from "axios";
import { ServiceBase } from "./service-base";
import store from "@/store/index";

export default class DataService extends ServiceBase {
  constructor() {
    super("data");
  }

  async getData(type: SolarDataType): Promise<number> {
    const siteId = store.getters["userModule/getSelectedSiteId"];

    const r = await axios.get(
      this.urlBuilder
        .withFragment(siteId)
        .withFragment(type.toString())
        .toString()
    );
    return r.data;
  }
}
