import { Line } from "./line.model";
import { Landmark } from "./landmark.model";
import { Direction } from "@/models/point.enum";

export class VerticalLine extends Line {
  isVertical = true;
  protected irrelevantDirections = [Direction.N, Direction.S];

  isAligned(landmark: Landmark, currentLandmark: Landmark): boolean {
    return (
      currentLandmark.posX > landmark.posX - Line.tolerance &&
      currentLandmark.posX < landmark.posX + Line.tolerance
    );
  }

  protected updatePosition(landmark: Landmark): void {
    this.position = landmark.posX;
  }
}
