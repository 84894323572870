import Config from "@/constants/config";
import { BackgroundTypes } from "./background.enum";
import { IMediaElement } from "./imedia-element";

export class BackgroundConfig implements IMediaElement {
  showBackgroundMenu = false;
  backgroundType = BackgroundTypes.Color;
  value = Config.DefaultColor;
  mediaUrl = "";

  constructor(init?: Partial<BackgroundConfig>) {
    Object.assign(this, init);
  }
}
