import { AlignCheck } from "@/components/scene-displayer/alignment/align-check";
import { IModuleConfigBase } from "@/models/modules/imodule-config-base";
import { Scene } from "@/models/scenes/scene.model";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

export interface SceneState {
  isEditMode: boolean;
  selectedModule: number | null;
  scene: Scene;
  alignCheck: AlignCheck;
}

export const state: SceneState = {
  isEditMode: false,
  selectedModule: null,
  scene: new Scene(),
  alignCheck: new AlignCheck(),
};

const actions: ActionTree<SceneState, RootState> = {
  changeScene({ state, commit }, payload: Scene) {
    if (payload) {
      state.scene = payload;
      commit("onSceneChanged", payload);
    }
  },
  deleteModule({ state, commit }) {
    const toRemove = state.selectedModule;

    if (toRemove !== null) {
      if (state.selectedModule === toRemove) {
        commit("selectModule", null);
      }

      commit("onModuleDeleted");
      commit("deleteModule", toRemove);
    }
  },
  addModule({ state, commit }, payload: IModuleConfigBase) {
    commit("addModule", payload);
    commit("onModuleAdded", payload);

    if (!state.isEditMode) {
      commit("switchMode");
    }
  },
  selectModule({ commit }, moduleIndex: number | null) {
    if (moduleIndex !== state.selectedModule) {
      commit("onModuleSelected", moduleIndex);
      commit("selectModule", moduleIndex);
    }
  },
};

const getters: GetterTree<SceneState, RootState> = {
  getModules(state): IModuleConfigBase[] {
    return state.scene.modules;
  },
  getSelectedModuleIndex(state): number | null {
    return state.selectedModule;
  },
  getCurrentScene(state): Scene {
    return state.scene;
  },
  isEditMode(state): boolean {
    return state.isEditMode;
  },
  getAlignCheck(state): AlignCheck {
    return state.alignCheck;
  },
  getMaxModuleZIndex(state): number {
    return Math.max(...state.scene.modules.map((m) => m.zIndex.value));
  },
};

const mutations: MutationTree<SceneState> = {
  onSceneChanged(state, scene: Scene) {
    state.alignCheck.onSceneChanged(scene);
  },
  onModuleAdded(state, module: IModuleConfigBase) {
    state.alignCheck.onModuleAdded(module);
  },
  onModuleDeleted() {
    state.alignCheck.onModuleDeleted();
  },
  onModuleSelected(state, moduleIndex: number | null) {
    if (state.selectedModule != null) {
      state.alignCheck.onModuleUnselected(
        state.scene.modules[state.selectedModule]
      );
    }

    if (moduleIndex != null) {
      state.alignCheck.onModuleSelected(state.scene.modules[moduleIndex]);
    }
  },
  switchMode(state) {
    state.isEditMode = !state.isEditMode;
  },
  addModule(state, module: IModuleConfigBase) {
    state.scene.modules.push(module);
    module.zIndex.value = state.scene.modules.length - 1;
  },
  selectModule(state, payload: number | null) {
    state.selectedModule = payload;
  },
  deleteModule(state, index: number) {
    const toRemove = state.scene.modules[index];

    const removedZIndex = toRemove.zIndex.value;
    state.scene.modules.splice(index, 1);

    state.scene.modules
      .filter((m) => m.zIndex.value > removedZIndex)
      .forEach((m) => m.zIndex.value--);
  },
  sendModuleToFront(state, currentZIndex: number) {
    const targetModule = state.scene.modules.find(
      (m) => m.zIndex.value === currentZIndex
    );

    if (!targetModule) {
      return;
    }

    state.scene.modules
      .filter((m) => m.zIndex.value > currentZIndex)
      .forEach((m) => m.zIndex.value--);

    targetModule.zIndex.value = state.scene.modules.length - 1;
  },
  sendModuleToBack(state, currentZIndex: number) {
    const targetModule = state.scene.modules.find(
      (m) => m.zIndex.value === currentZIndex
    );

    if (!targetModule) {
      return;
    }

    state.scene.modules
      .filter((m) => m.zIndex.value < currentZIndex)
      .forEach((m) => m.zIndex.value++);

    targetModule.zIndex.value = 0;
  },
  moveModuleForward(state, currentZIndex: number) {
    if (currentZIndex === state.scene.modules.length - 1) {
      return;
    }

    const targetModule = state.scene.modules.find(
      (m) => m.zIndex.value === currentZIndex
    );

    if (!targetModule) {
      return;
    }

    state.scene.modules
      .filter((m) => m.zIndex.value === currentZIndex + 1)
      .forEach((m) => m.zIndex.value--);

    targetModule.zIndex.value++;
  },
  moveModuleBackward(state, currentZIndex: number) {
    if (currentZIndex === 0) {
      return;
    }

    const targetModule = state.scene.modules.find(
      (m) => m.zIndex.value === currentZIndex
    );

    if (!targetModule) {
      return;
    }

    state.scene.modules
      .filter((m) => m.zIndex.value === currentZIndex - 1)
      .forEach((m) => m.zIndex.value++);

    targetModule.zIndex.value--;
  },
  initAlignCheck(
    state,
    screenSize: {
      width: number;
      height: number;
    }
  ) {
    state.alignCheck.init(screenSize.width, screenSize.height);
  },
};

export const profile: Module<SceneState, RootState> = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
