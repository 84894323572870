import { Product } from "@/models/product.enum";

export class RequestPasswordResetDTO {
  mail: string;
  product: Product = Product.solarOnTV;

  constructor(mail: string) {
    this.mail = mail;
  }
}
