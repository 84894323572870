import { BackgroundConfig } from "@/models/background/background-config.model";
import { IModuleConfigBase } from "@/models/modules/imodule-config-base";
import { Media } from "../files/media.model";
import { ModuleTypes } from "../modules/modules.enum";
import { loadMediaToElement } from "@/utils/media.utils";

export class Scene {
  id!: string;
  name!: string;
  modules: IModuleConfigBase[] = [];
  background = new BackgroundConfig();
  isDefault = false;
  medias: Media[] = [];

  constructor(init?: Partial<Scene>) {
    Object.assign(this, init);

    this.loadMedias();
  }

  private loadMedias(): void {
    loadMediaToElement(this.background, this.medias);

    this.modules.forEach((module) => {
      if (module.type === ModuleTypes.Image) {
        module.loadMedias(this.medias);
      }
    });
  }
}
