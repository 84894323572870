import Config from "../constants/config";
import { UrlBuilder } from "./url-builder";

export abstract class ServiceBase {
  private readonly baseUri: string;

  get urlBuilder() {
    return new UrlBuilder(this.baseUri);
  }

  protected constructor(urlFragment: string) {
    this.baseUri = `${Config.API}/${urlFragment}`;
  }
}
