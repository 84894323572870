import { Direction } from "@/models/point.enum";

export abstract class Landmark {
  abstract relevantDirections: Direction[];

  posX!: number;
  posY!: number;

  abstract setPosition(
    posX: number,
    posY: number,
    width: number,
    height: number
  ): void;

  constructor(posX: number, posY: number, width: number, height: number) {
    this.setPosition(posX, posY, width, height);
  }

  isRelevant(direction: Direction): boolean {
    return this.relevantDirections.indexOf(direction) > -1;
  }
}
