import { NotificationLevel } from "@/models/notification-level.enum";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

const notificationTimeout = 2500;

export interface NotificationsState {
  message: string;
  level: NotificationLevel;
  timeout: number;
}

export const state: NotificationsState = {
  message: "",
  level: NotificationLevel.info,
  timeout: notificationTimeout,
};

const actions: ActionTree<NotificationsState, RootState> = {};

const getters: GetterTree<NotificationsState, RootState> = {};

const mutations: MutationTree<NotificationsState> = {
  showMessage(state, payload: NotificationsState) {
    state.message = payload.message;
    state.level = payload.level;
    state.timeout = payload.timeout ?? notificationTimeout;
  },
};

export const profile: Module<NotificationsState, RootState> = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
