import { Direction } from "@/models/point.enum";
import { Landmark } from "./landmark.model";

export abstract class Line {
  protected static tolerance = 5;

  abstract isVertical: boolean;
  position = 0;
  display = false;

  protected staticLandmark: Landmark | null = null;
  protected dynamicLandmark: Landmark | null = null;
  protected abstract irrelevantDirections: Direction[];
  private isRelevant = true;

  protected abstract isAligned(landmark: Landmark, clm: Landmark): boolean;
  protected abstract updatePosition(landmark: Landmark): void;

  init(direction: Direction): void {
    this.isRelevant = !this.irrelevantDirections.includes(direction);
  }

  canBeAligned(landmark: Landmark, clm: Landmark): boolean {
    return this.isRelevant && this.isAligned(landmark, clm);
  }

  attach(landmark: Landmark, clm: Landmark): void {
    this.display = true;
    this.staticLandmark = landmark;
    this.dynamicLandmark = clm;

    this.updatePosition(landmark);
  }

  isStillAligned(): boolean {
    return (
      !!this.staticLandmark &&
      !!this.dynamicLandmark &&
      this.isAligned(this.staticLandmark, this.dynamicLandmark)
    );
  }

  reset(): void {
    this.display = false;
    this.staticLandmark = null;
    this.dynamicLandmark = null;
    this.isRelevant = true;
  }
}
