import { RequestType } from "@/models/request-type.enum";
import { SolarDataType } from "@/models/solar-data-type.enum";
import DataService from "@/services/data.service";
import Vue from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

interface DataHolder {
  [key: string]: number;
}

export interface DataState {
  updateDataInterval: number | null;
  fetchInterval: number;

  data: DataHolder;
  requiredData: SolarDataType[];
  requestLocks: Set<RequestType>;
  requestsQueue: Set<SolarDataType>;
  dataService: DataService;
}

export const state: DataState = {
  updateDataInterval: null,
  fetchInterval: 15,

  data: {},
  requiredData: [],
  requestLocks: new Set<RequestType>(),
  requestsQueue: new Set<SolarDataType>(),
  dataService: new DataService(),
};

const actions: ActionTree<DataState, RootState> = {
  registerDataType({ state, dispatch }, types: SolarDataType[]) {
    for (const type of types) {
      state.requiredData.push(type);

      if (state.data[type] === undefined) {
        dispatch("requestData", type);

        dispatch("resetDataUpdating");
      }
    }
  },

  fetchData({ state }, dataType: SolarDataType) {
    return new Promise((resolve, reject) => {
      state.dataService
        .getData(dataType)
        .then((res) => {
          Vue.set(state.data, dataType, res);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  requestData({ state, dispatch }, dataType: SolarDataType) {
    const relatedLock = getRelatedRequest(dataType);

    if (state.requestLocks.has(relatedLock)) {
      state.requestsQueue.add(dataType);

      return;
    }

    state.requestLocks.add(relatedLock);

    dispatch("fetchData", dataType)
      .then(
        () =>
          (state.requestsQueue = new Set(
            Array.from(state.requestsQueue).filter((rq) => rq !== dataType)
          ))
      )
      .then(() => dispatch("releaseLock", relatedLock))
      .catch(() => dispatch("deleteLocks", relatedLock))
      .finally(() => state.requestLocks.delete(relatedLock));
  },

  releaseLock({ state, dispatch }, lock: RequestType) {
    Promise.all(
      Array.from(state.requestsQueue)
        .filter((rq) => getRelatedRequest(rq) === lock)
        .map((rq) => dispatch("fetchData", rq))
    ).finally(() => {
      dispatch("deleteLocks", lock);
    });
  },

  deleteLocks({ state }, lock: RequestType) {
    state.requestsQueue = new Set(
      Array.from(state.requestsQueue).filter(
        (rq) => getRelatedRequest(rq) !== lock
      )
    );
  },

  stopDataUpdating({ state }) {
    if (state.updateDataInterval) {
      clearInterval(state.updateDataInterval);
    }
  },

  unregisterDataType({ state }, types: SolarDataType[]) {
    for (const type of types) {
      const typeIndex = state.requiredData.indexOf(type);

      if (typeIndex > -1) {
        state.requiredData.splice(typeIndex, 1);
      }
    }
  },

  resetDataUpdating({ state, dispatch }, eraseData = false) {
    if (eraseData) {
      state.data = {};
    }

    dispatch("stopDataUpdating").then(() => dispatch("setupDataUpdating"));
  },

  setupDataUpdating({ state, dispatch }) {
    state.updateDataInterval = setInterval(
      () => dispatch("updateData"),
      60000 * state.fetchInterval
    );
  },

  updateData({ state, dispatch }) {
    [...new Set(state.requiredData)].forEach((type) => {
      dispatch("requestData", type);
    });
  },
};

const getters: GetterTree<DataState, RootState> = {
  getInstantPower(state): number {
    return getDataValue(state, SolarDataType.CurrentPower);
  },
  getDayEnergy(state): number {
    return getDataValue(state, SolarDataType.LastDayEnergy);
  },
  getMonthEnergy(state): number {
    return getDataValue(state, SolarDataType.LastMonthEnergy);
  },
  getYearEnergy(state): number {
    return getDataValue(state, SolarDataType.LastYearEnergy);
  },
  getAllTimeEnergy(state): number {
    return getDataValue(state, SolarDataType.LifetimeEnergy);
  },
  getSelfConsumption(state): number {
    return getDataValue(state, SolarDataType.EnergySelfConsumption);
  },
  getProduction(state): number {
    return getDataValue(state, SolarDataType.EnergyProduction);
  },
  getConsumption(state): number {
    return getDataValue(state, SolarDataType.EnergyConsumption);
  },
};

const mutations: MutationTree<DataState> = {
  setFetchInterval(state, payload: number) {
    state.fetchInterval = payload;
  },
};

export const profile: Module<DataState, RootState> = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};

function getDataValue(state: DataState, type: SolarDataType): number {
  return state.data[type] || 0;
}

function getRelatedRequest(type: SolarDataType): RequestType {
  switch (type) {
    case SolarDataType.CurrentPower:
    case SolarDataType.LastDayEnergy:
    case SolarDataType.LastMonthEnergy:
    case SolarDataType.LastYearEnergy:
    case SolarDataType.LifetimeEnergy:
      return RequestType.SolarEdgeOverview;

    case SolarDataType.EnergyConsumption:
    case SolarDataType.EnergySelfConsumption:
    case SolarDataType.EnergyProduction:
      return RequestType.SolarEdgeEnergyDetails;

    default:
      throw new Error("not implemented yet");
  }
}
