import { Line } from "./line.model";
import { Landmark } from "./landmark.model";
import { Direction } from "@/models/point.enum";

export class HorizontalLine extends Line {
  isVertical = false;
  protected irrelevantDirections = [Direction.E, Direction.W];

  isAligned(landmark: Landmark, currentLandmark: Landmark): boolean {
    return (
      currentLandmark.posY > landmark.posY - Line.tolerance &&
      currentLandmark.posY < landmark.posY + Line.tolerance
    );
  }

  protected updatePosition(landmark: Landmark): void {
    this.position = landmark.posY;
  }
}
