import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const languages = [
  {
    flag: "fr",
    language: "fr",
    title: "Français",
  },
];

const messages = {
  fr: {
    actions: {
      delete: "Supprimer",
      copy: "Copier le style",
      paste: "Appliquer le style",
    },
    common: {
      save: "Sauvegarder",
      load: "Charger",
      close: "Fermer",
      or: "OU",
      rowsPerPage: "Lignes par page :",
      tableActions: "Actions",
      allItems: "Tout",
      errors: {},
    },
    components: {
      mailInput: {
        mailInvalid: "Veuillez entrer une adresse e-mail valide",
        mailPlaceholder: "Adresse e-mail",
      },
      fileDrop: {
        select: "Selectionner ou déposer un fichier...",
      },
      backgroundDisplayer: {
        tab: {
          color: {
            title: "Couleur",
          },
          image: {
            title: "Image",
          },
          video: {
            title: "Vidéo",
          },
        },
      },
      moduleSelector: {
        searchModule: "Rechercher un module",
      },
      navigationButtons: {
        enableEditMode: "Passer en mode édition",
        disableEditMode: "Quitter le mode édition",
        backgroundButton: "Changer le fond d'écran",
        addScene: "Créer une nouvelle scène",
      },
      sitesSelector: {
        searchSite: "Rechercher un site",
        goTo: "Accéder au site",
        noSites: "Aucun site à afficher",
        table: {
          siteName: "Nom",
        },
        errors: {
          sitesFetchFailed:
            "Les sites accessibles n'ont pas pu être récupérés.",
        },
      },
      sceneList: {
        defineAsMain: "Définir comme scène principale",
        deleteScene: "Supprimer la scène",
        isMain: "Scène principale",
        changedDefaultScene: "La scène principale a été correctement définie.",
        deletedScene: "La scène a été supprimée",
        confirmMessage: "Supprimer définitivement la scène ?",
        cancel: "Annuler",
        delete: "Supprimer",
        errors: {
          loadScenesFailed:
            "Les scènes n'ont pas pu être récupérées. Veuillez rééssayer plus tard.",
          setDefaultSceneFailed: "La scène principale n'a pas pu être définie.",
          deleteSceneFailed: "La scène n'a pas pu être supprimée",
        },
      },
      scenesMenu: {
        loadScene: "Charger une scène",
      },
      saveScenesMenu: {
        saveScene: "Sauvegarder la scène",
        newSceneName: "Titre de la nouvelle scène",
        override: "Sauvegarder et remplacer",
        saveAs: "Sauvegarder et créer",
        saveSceneFailed:
          "La scène n'a pas pu être sauvegardée. Veuillez rééssayer plus tard.",
        saveSceneSuccess: "La scène a été sauvegardée.",
      },
      startHelpMenu: {
        addTotalProduction: "Ajouter un module 'Production totale'",
        addText: "Ajouter un module 'Texte'",
        displayMenu:
          "Afficher la liste des modules en cliquant sur le bouton 'Menu'",
      },
      modules: {
        instantPower: {
          title: "Puissance instantanée",
        },
        dayEnergy: {
          title: "Production du jour",
        },
        dailyConsumption: {
          title: "Consommation du jour",
        },
        monthEnergy: {
          title: "Production du mois",
        },
        yearEnergy: {
          title: "Production de l'année",
        },
        allTimeEnergy: {
          title: "Production totale",
        },
        carbon: {
          title: "CO2 évité",
        },
        tree: {
          title: "Arbres plantés",
        },
        autoConsumption: {
          title: "Autoconsommation",
        },
        autoProduction: {
          title: "Autoproduction",
        },
        battery: {
          title: "Charge de la batterie",
        },
        color: {
          title: "Couleur",
        },
        text: {
          title: "Texte",
          default: "Texte",
        },
        image: {
          title: "Image",
          dragFileText: "ou faites glisser une image ici",
          selectFileButton: "Selectionnez",
        },
      },
      configMenu: {
        groups: {
          general: "Général",
          title: "Titre",
          data: "Données",
        },
        parameters: {
          titleFontSize: "Taille de police du titre",
          titleFontColor: "Couleur du titre",
          titleAlignment: "Alignement du titre",
          titleIndentation: "Indentation du titre",
          contentFontSize: "Taille de police du contenu",
          contentFontColor: "Couleur",
          contentAlignment: "Alignement du texte",
          width: "Largeur",
          height: "Hauteur",
          posX: "Position horizontale",
          posY: "Position verticale",
          opacity: "Opacité",
          backgroundColor: "Couleur de fond",
          textFontSize: "Taille de police du texte",
          textFontColor: "Couleur du texte",
          mainProgressColor: "Couleur de jauge",
          secondProgressColor: "Couleur de fond de jauge",
          progressWidth: "Épaisseur du cercle",
          zIndex: "Profondeur",
          zIndexActions: {
            moveForward: "Avancer",
            moveBackward: "Reculer",
            sendToFront: "Envoyer au premier plan",
            sendToBack: "Envoyer en arrière-plan",
          },
          unit: "Unité",
          whConverter: "Unité",
          wConverter: "Unité",
          textContent: "Texte",
          elevation: "Ombre portée",
          image: "Image",
        },
      },
    },
    views: {
      reset: {
        title: "Réinitialisation de mot de passe",
        hint: "Pour assurer la sécurité de votre compte, veuillez choisir un nouveau mot de passe contenant au moins :\n\n- {length} caractères\n- une lettre majuscule\n- une lettre minuscule\n- un chiffre",
        passwordPlaceholder: "Nouveau mot de passe",
        confirmPasswordPlaceholder: "Confimez votre nouveau mot de passe",
        confirmReset: "Réinitialiser",
        form: {
          passwordRequired: "Le mot de passe ne peut pas être vide",
          passwordLengthRule:
            "Le mot de passe doit avoir au moins {length} caractères",
          passwordUppercaseRule:
            "Le mot de passe doit contenir une lettre majuscule",
          passwordLowercaseRule:
            "Le mot de passe doit contenir une lettre minuscule",
          passwordNumberRule: "Le mot de passe doit contenir un chiffre",
          passwordMatchRule: "Les mots de passe ne correspondent pas",
        },
        errors: {
          resetFailed:
            "La réinitialisation n'a pas pu être effectuée. Veuillez rééssayer plus tard.",
        },
      },
      requestReset: {
        title: "Mot de passe oublié ?",
        hint: "Entrez votre adresse e-mail. Nous vous enverrons les indications pour réinitialiser votre mot de passe.",
        goBack: "Retour",
        continue: "Continuer",
        errors: {
          resetFailed:
            "La réinitialisation n'a pas pu être effectuée. Veuillez rééssayer plus tard.",
        },
      },
      requestResetSuccess: {
        title: "Demande de réinitialisation effectuée",
        hint: "Votre demande de réinitialisation de mot de passe a été prise en compte. Les instructions pour réinitialiser votre mot de passe ont été envoyées à l'adresse e-mail que vous avez fournie. Veuillez vérifier votre boîte de réception (y compris le dossier de courrier indésirable) pour trouver l'e-mail de réinitialisation. Suivez les instructions fournies pour choisir un nouveau mot de passe.",
        goToLogin: "Retour à la page de connexion",
      },
      resetPasswordSuccess: {
        title: "Réinitialisation effectuée",
        hint: "Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter à votre compte en utilisant votre nouveau mot de passe.",
        goToLogin: "Retour à la page de connexion",
      },
      login: {
        title: "Se connecter à Solar On TV",
        passwordPlaceholder: "Mot de passe",
        login: "Se connecter",
        forgotPassword: "Mot de passe oublié ?",
        form: {
          passwordRequired: "Le mot de passe ne peut pas être vide",
        },
        errors: {
          loginFailed: "Adresse e-mail ou mot de passe incorrect",
        },
      },
      scene: {
        currentSite: "Site courant",
        logout: "Se déconnecter",
        errors: {
          siteNotFound: "Le site selectionné n'est pas accessible.",
        },
      },
      settings: {
        sites: "Sites",
      },
    },
  },
};

const i18n = new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages,
});

export { languages, i18n };
