import { Site, SiteScene } from "@/models/sites/site.model";
import { RefreshedTokens } from "@/models/user/refresh-token";
import { TokenUser } from "@/models/user/token-user.model";
import router from "@/router";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";
import { RightLevel } from "@/models/right-level.enum";

const refreshToken = localStorage.getItem("refreshToken");
const lastSiteId = localStorage.getItem("lastSiteId");
const userName = localStorage.getItem("userName");

export interface UserState {
  token: string | undefined;
  refreshToken: string | undefined;
  selectedSiteId: string | undefined;
  selectedSiteName: string | undefined;
  canEditSite: boolean;
  userName: string | undefined;
}

export const state: UserState = {
  token: undefined,
  refreshToken: refreshToken ?? undefined,
  selectedSiteId: lastSiteId ?? undefined,
  selectedSiteName: "",
  canEditSite: false,
  userName: userName ?? undefined,
};

const actions: ActionTree<UserState, RootState> = {
  selectSite({ state }, site: Site) {
    state.selectedSiteId = site.id;
    localStorage.setItem("lastSiteId", state.selectedSiteId);
  },
  loadUser({ state }, name: string) {
    state.userName = name;
    localStorage.setItem("userName", state.userName);
  },
  loadSiteDetails({ state, dispatch, commit }, site: SiteScene) {
    state.selectedSiteName = site.name;
    state.canEditSite = site.rightLevel !== RightLevel.view;

    commit("dataModule/setFetchInterval", site.fetchInterval, { root: true });
    dispatch("dataModule/resetDataUpdating", true, { root: true });
    dispatch("sceneModule/changeScene", site.defaultScene, { root: true });
  },
  successLogin({ dispatch }, user: TokenUser) {
    dispatch("refreshTokens", user.refreshedTokens);
    dispatch("loadUser", user.name);

    router.push("/");
  },
  refreshTokens({ state }, newTokens: RefreshedTokens) {
    state.token = newTokens.accessToken;
    state.refreshToken = newTokens.refreshToken;

    localStorage.setItem("refreshToken", state.refreshToken);
  },
  successLogout({ state }) {
    state.token = undefined;
    state.refreshToken = undefined;
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("lastSiteId");

    router.push("/login");
  },
};

const getters: GetterTree<UserState, RootState> = {
  jwtToken(state): string | undefined {
    return state.token;
  },
  refreshToken(state): string | undefined {
    return state.refreshToken;
  },
  isLoggedIn(state): boolean {
    return !!state.token || !!state.refreshToken;
  },
  getSelectedSiteId(state): string {
    return state.selectedSiteId || "";
  },
  getSelectedSiteName(state): string {
    return state.selectedSiteName || "";
  },
  canEditSite(state): boolean {
    return state.canEditSite;
  },
  getUserName(state): string {
    return state.userName ?? "";
  },
};

const mutations: MutationTree<UserState> = {};

export const profile: Module<UserState, RootState> = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
