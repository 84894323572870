










import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class NotificationBar extends Vue {
  unsubscribe!: () => void;
  show = false;
  level = "";
  message = "";
  timeout = -1;

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "notificationsModule/showMessage") {
        this.level = state.notificationsModule.level;
        this.message = state.notificationsModule.message;
        this.timeout = state.notificationsModule.timeout;

        this.show = true;
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
