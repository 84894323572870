import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from ".";

export interface ColorsState {
  colors: string[];
}

export const state: ColorsState = {
  colors: [],
};

const actions: ActionTree<ColorsState, RootState> = {};

const getters: GetterTree<ColorsState, RootState> = {
  getColors(state): string[] {
    return state.colors;
  },

  getFormattedColors(state): string[][] {
    return state.colors.map((c) => [c]);
  },
};

const mutations: MutationTree<ColorsState> = {
  addColor(state, payload: string) {
    if (!state.colors.includes(payload)) {
      state.colors.push(payload);
    }
  },
};

export const profile: Module<ColorsState, RootState> = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
